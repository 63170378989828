const config = {
    s3: {
        REGION: "eu-west-2",
        BUCKET: "notes-app-upload-cinammon",
    },
    apiGateway: {
        REGION: "eu-west-2",
        URL: "https://h2k1dkggmi.execute-api.eu-west-2.amazonaws.com/prod",
    },
    cognito: {
        REGION: "eu-west-2",
        USER_POOL_ID: "eu-west-2_Vz0GoCuDB",
        APP_CLIENT_ID: "1vru5cpc5ijk60j6j1tu6umt10",
        IDENTITY_POOL_ID: "eu-west-2:c89e922b-92a1-4920-acf8-104ea7b2eee6",
    },
    MAX_ATTACHMENT_SIZE: 5000000,
    STRIPE_KEY: "pk_test_51H0E5hGrkcgF63zs62xLdwk9ZreqbpESWJeaGydJDAWifjYC00Hsz1gVggjiJxKpQh7KlVvoyIScM7XoqQCynkKv00WugjSRDc",
};

export default config;